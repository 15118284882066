import scrollTo from 'scroll-to';
import validate from 'jquery-validation'
import ajaxSubmit from 'jquery-form'

// Animate the form open
const expandContactForm = (origHeight) => {
  $("#ContactFormHolder").animate({opacity: 0}, 333,
    () => {
      $("#ContactFormHolder").hide();
      $("#ContactFormContainer").animate({opacity: 1, height: `+=${origHeight}`}, 600,);
    }
  );
};

// Validate the form
const validateContactForm = form => {
  return form.validate({
    rules: {
      "message[first_name]": {
        required: true,
        minlength: 2,
        normalizer: function(value) { return $.trim(value); }
      },
      "message[last_name]": {
        required: true,
        minlength: 2,
        normalizer: function(value) { return $.trim(value); }
      },
      "message[email]": {
        required: true,
        email: true,
        normalizer: function(value) { return $.trim(value); }
      },
      "message[company_name]": {
        required: false,
        minlength: 2,
        normalizer: function(value) { return $.trim(value); }
      },
      "message[country]": {
        required: false,
      },
      "message[customer_category]": {
        required: true,
      },
      "message[reason_for_contact]": {
        required: true,
        minlength: 9,
        normalizer: function(value) { return $.trim(value); }
      },
      "message[terms_of_service]": {
        required: true,
      },
    },
    messages: {
      "message[first_name]": {
        required: "First name needed to contact you",
        minlength: "Must have a minimum length of 2 characters",
      },
      "message[last_name]": {
        required: "Last name needed to contact you",
        minlength: "Must have a minimum length of 2 characters",
      },
      "message[email]": {
        required: "Email address needed to contact you",
        email: "Must be a valid email address",
      },
      "message[company_name]": {
        minlength: "Minimum length of 2 characters",
      },
      "message[country]": {
      },
      "message[customer_category]": {
        required: "Customer category needed to contact you",
      },
      "message[reason_for_contact]": {
        required: "Reason needed to better help you",
        minlength: "Must have a minimum length of 9 characters",
      },
      "message[terms_of_service]": {
        required: "We need your approval",
      },
    }
  });
}

$(function(){

  // Measure Height on load and hide it
  const formHeight = $("#ContactFormContainer").height();
  $("#ContactFormContainer").css({ opacity: 0, height: 0 });

  // Scroll to contact form
  const x = $("#ContactFormScroll").offset().left;
  const y = $("#ContactFormScroll").offset().top - 180;
  $("#ContantFormLink").click((evt) => {
    scrollTo(x, y, {
      ease: 'linear',
      duration: 900
    });
    expandContactForm(formHeight);
    evt.preventDefault();
  });

  // Expand the form
  $("#ContactFormHolder").click((evt) => {
    expandContactForm(formHeight);
    evt.preventDefault();
  });

  // Handle the checkbox click
  $("#ContactFormCheckBox").click(evt => {
    $("#message_terms_of_service").click();
    evt.preventDefault();
  });

  // Handle on onSubmit event
  $("#SendContactForm").click(evt => {
    let form = $("#new_message");
    validateContactForm(form);
    // Send the data after checking result
    if (form.valid()) {
      $(form).ajaxSubmit(() => {
        // Done Submitting
        $("#SendContactForm").animate({width: "100%", background: "#0B3347", color: "#FFFFFF"}, 333,
          () => {
            form.trigger("reset");
            $("#SendContactForm").off("click").click(evt => { evt.preventDefault(); });
            $("#SendContactForm").text("Your Request Has Been Sent");
            $("#SendContactForm").removeClass("btn-white-hollow");
            $("#SendContactForm").addClass("btn-secondary-static");
            $("#SendContactForm").css("cursor", "default");
          }
        );
      });
    };
    evt.preventDefault();
  });

});
